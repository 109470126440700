import { post, get } from './requests'

export const createTradesmanSequence = (key, serviceOrderId, payload) =>
  post(['serviceorders', serviceOrderId, 'sequences'], { key, transformPayload: true }, payload)

export const getTradesmanSequence = (key, serviceOrderId) =>
  get(['serviceorders', serviceOrderId, 'sequences'], { key })

export const acceptByTradesmanService = (key, serviceOrderId, payload) =>
  post(
    ['serviceorders', serviceOrderId, 'accept-serviceorder-for-provider'],
    { key, transformPayload: true },
    payload
  )

export const acceptIBByTradesmanService = (key, serviceOrderId, payload) =>
  post(
    ['serviceorders', serviceOrderId, 'accept-inner-budget-for-provider'],
    { key, transformPayload: true },
    payload
  )
