import { post, patch, get } from './requests'

export const createEmailService = key => (agencyId, payload) => {
  return post(
    ['email-config', agencyId],
    {
      key,
      transformPayload: true,
      transformFormData: true,
      formData: true,
    },
    payload
  )
}

export const editEmailService = key => (agencyId, payload) => {
  return patch(
    ['email-config', agencyId],
    {
      key,
      transformPayload: true,
      transformFormData: true,
      formData: true,
    },
    payload
  )
}

export const testEmailService = key => payload => {
  return post(
    ['test-email-connection'],
    {
      key,
      transformPayload: true,
      transformFormData: true,
      formData: true,
    },
    payload
  )
}

export const getEmailService = key => agencyId =>
  get(['email-config', agencyId], { key, transformPayload: true })
