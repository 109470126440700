import humps from 'humps'

import { get, patch, post, del } from './requests'
import { getToken } from '_/utils/token'

export const getAgencies = key => params =>
  get(['agencyboard'], { key, params: humps.decamelizeKeys(params), transformPayload: true })

export const getAgency = key => agencyId =>
  get(['agency', agencyId], { key, transformPayload: true })

export const getAgencyFull = key => agencyId =>
  get(['agency', agencyId, 'full-agency-detail'], { key, transformPayload: true })

export const getAgenciesSimple = key => () =>
  get(['simple-agency'], { key, transformPayload: true })

export const updateAgency = key => (agencyId, payload) => {
  const shouldTransformIntoFormData = payload?.brandLogoPngUrl instanceof Blob
  return patch(
    ['agency', agencyId],
    {
      key,
      transformPayload: true,
      transformFormData: shouldTransformIntoFormData,
      formData: shouldTransformIntoFormData,
    },
    payload
  )
}
export const createAgency = key => payload => {
  const shouldTransformIntoFormData = payload?.brandLogoPngUrl instanceof Blob
  return post(
    ['agency'],
    {
      key,
      transformPayload: true,
      transformFormData: shouldTransformIntoFormData,
      formData: true,
    },
    payload
  )
}

export const getAgencyStaffUsers = (agencyId, userId = undefined) => {
  const headers = getToken()
  const urlFragmentList = userId
    ? ['agency', agencyId, 'staff-users', userId]
    : ['agency', agencyId, 'staff-users']

  return get(urlFragmentList, { ...headers, transformPayload: true })
}

export const addAgencyStaffUser = key => (agencyId, payload) =>
  post(['agency', agencyId, 'staff-users'], { key, transformPayload: true }, payload)

export const deleteAgencyStaffUser = key => (agencyId, staffId) =>
  del(['agency', agencyId, 'staff-users', staffId], { key, transformPayload: true })

export const deleteAgencyContract = key => (agencyId, attachmentId) =>
  patch(['agency', agencyId, 'delete-contract'], { key }, { attachmentId })

export const updateAgencyStaffUser = key => (agencyId, staffId, payload) =>
  patch(['agency', agencyId, 'staff-users', staffId], { key, transformPayload: true }, payload)

export const getServiceOrderResponsible =
  key =>
  (agencyId, responsibleId = null) =>
    get(['agency', agencyId, 'responsible-options'], {
      key,
      params: { responsible_id: responsibleId },
    })

export const getOptionsFieldName = key => agencyId =>
  get(['options-field-name'], { key, transformPayload: true, params: { agency: agencyId } })

export const getSectorsAgencyByUserId = key => userId =>
  get(['agency', userId, 'find-sectors-agency'], { key, transformPayload: true })

export const getPages = key => agencyId =>
  get(['form-pages'], { key, transformPayload: true, params: { agency_id: agencyId } })

export const getAdditionalTakeRatesByAgency = key => agencyId =>
  get(['additional-take-rates'], { key, transformPayload: true, params: { agency_id: agencyId } })

export const getAgencyDetails = key => agencyId =>
  get(['agency', agencyId, 'agency-details'], { key })

export const downloadReport = () => {
  const headers = getToken()
  return get(['agency', 'download-report'], { ...headers })
}
