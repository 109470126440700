import { createFormDataDocuments } from '_utils/helpers'
import humps from 'humps'

import { get, patch, post, put, del } from './requests'

export const getBudget = (key, serviceOrderId, budgetId) =>
  get(['serviceorders', serviceOrderId, 'budgets', budgetId], { key })

export const getBudgetById = (key, budgetId, params) =>
  get(['budgets', budgetId], { key, params: humps.decamelizeKeys(params) })

export const deleteBudget = (key, serviceOrderId, budgetId) =>
  del(['serviceorders', serviceOrderId, 'budgets', budgetId, 'delete-budget'], { key })

export const getBudgetByServiceOrder = (key, serviceOrderId) =>
  get(['serviceorders', serviceOrderId, 'search-tradesman-budget'], { key })

export const createOpenProposeBudget = (key, serviceOrderId) =>
  put(['serviceorders', serviceOrderId, 'open-propose-budget'], { key })

export const formDataHeader = {
  'Content-Type': 'multipart/form-data; charset=UTF-8',
}

export const updateBudget = (key, serviceOrderId, budgetId, payload, options = {}) =>
  patch(['serviceorders', serviceOrderId, 'budgets', budgetId], { key, ...options }, payload)

export const getPayerOptions = (key, serviceOrderId, budgetId) =>
  get(['serviceorders', serviceOrderId, 'budgets', budgetId, 'get-options'], { key })

export const deleteBudgetItem = (key, serviceOrderId, budgetId, itemId) =>
  del(['serviceorders', serviceOrderId, 'budgets', budgetId, 'budgetitems', itemId], { key })

export const updateBudgetItem = (key, serviceOrderId, budgetId, itemId, payload) =>
  patch(
    ['serviceorders', serviceOrderId, 'budgets', budgetId, 'budgetitems', itemId],
    { key },
    payload
  )

export const deleteBudgetPicture = (key, serviceOrderId, budgetId, pictureId) =>
  del(['serviceorders', serviceOrderId, 'budgets', budgetId, 'budgetpictures', pictureId], { key })

export const deleteBudgetPictures = (key, serviceOrderId, budgetId, payload) =>
  patch(
    ['serviceorders', serviceOrderId, 'budgets', budgetId, 'delete-budget-pictures'],
    {
      key,
    },
    payload
  )

export const addBudgetPicture = (key, serviceOrderId, budgetId, payload) => {
  const newPayload = { budget: budgetId, picture: payload }
  return post(
    ['serviceorders', serviceOrderId, 'budgets', budgetId, 'budgetpictures'],
    { key, transformPayload: false, formData: true },
    createFormDataDocuments(newPayload)
  )
}

export const importAttachments = (key, serviceOrderId, budgetId) =>
  post(['serviceorders', serviceOrderId, 'budgets', budgetId, 'import-attachments'], {
    key,
  })

export const refuseBudget = (key, serviceOrderId, budgetId, payload) =>
  patch(['serviceorders', serviceOrderId, 'budgets', budgetId, 'refuse'], { key }, payload)

export const clientRefuseBudget = (key, serviceOrderId, budgetId) =>
  post(['serviceorders', serviceOrderId, 'budgets', budgetId, 'person-paying-refuse-budget'], {
    key,
  })

export const unrefuseBudget = (key, serviceOrderId, budgetId) =>
  post(['serviceorders', serviceOrderId, 'budgets', budgetId, 'unrefuse'], { key })

export const sendBudgetCounterProposal = (key, serviceOrderId, budgetId, payload) =>
  patch(
    ['serviceorders', serviceOrderId, 'budgets', budgetId, 'counter-proposal'],
    { key },
    payload
  )

export const approvePicturesBudget = (key, serviceOrderId, budgetId, payload) =>
  patch(
    ['serviceorders', serviceOrderId, 'budgets', budgetId, 'approve-pictures'],
    { key, transformPayload: false, formData: true },
    createFormDataDocuments(payload, true)
  )

export const approveBudget = (key, serviceOrderId, budgetId, payload) => {
  const endpoint = ['serviceorders', serviceOrderId, 'budgets', budgetId, 'approve']
  const options = { key, transformPayload: false, formData: true }

  // NOTE: Only inner budgets (budget.proposeOpened = false) shouldn't have a payload
  if (!payload) {
    return patch(endpoint, options)
  }

  return patch(endpoint, options, createFormDataDocuments(payload, true))
}

export const clientApproveBudget = (key, serviceOrderId, budgetId, payload) =>
  post(
    ['serviceorders', serviceOrderId, 'budgets', budgetId, 'person-paying-approve-budget'],
    { key, transformPayload: true },
    payload
  )

export const cancelApproveBudget = (key, serviceOrderId, budgetId) =>
  post(['serviceorders', serviceOrderId, 'budgets', budgetId, 'cancel-approval'], {
    key,
    transformPayload: false,
  })

export const sendBudgetEmail = (key, serviceOrderId, budgetId) =>
  post(['serviceorders', serviceOrderId, 'budgets', budgetId, 'send-email-payment-user'], { key })

export const sendBudgetWhatsapp = (key, serviceOrderId, budgetId) =>
  post(['serviceorders', serviceOrderId, 'budgets', budgetId, 'send-wpp-payer'], { key })

export const approveCustomerBudget = (key, serviceOrderId, budgetId) =>
  post(['budgets', budgetId, 'accept-proposal'], {
    key,
    transformPayload: false,
  })

export const refuseCustomerBudget = (key, serviceOrderId, budgetId) =>
  post(['budgets', budgetId, 'refuse-proposal'], {
    key,
    transformPayload: false,
  })

export const createPropose = (key, serviceOrderId, budgetId) =>
  post(['serviceorders', serviceOrderId, 'budgets', budgetId, 'create-propose'], { key })

export const returnBudgetProvider = (key, serviceOrderId, budgetId, payload) =>
  post(
    ['serviceorders', serviceOrderId, 'budgets', budgetId, 'return-budget-provider'],
    { key, transformPayload: false },
    createFormDataDocuments(payload, true)
  )

export const sendWhatsapp = (key, serviceOrderId, budgetId) =>
  post(['serviceorders', serviceOrderId, 'budgets', budgetId, 'send-whatsapp'], { key })

// TODO: Move everything related to step_status into service-order folders (services and modules)
export const getStepStatusLog = (key, serviceOrderId) =>
  get(['serviceorders', serviceOrderId, 'step-status-log'], { key })

export const getEditExecutionInfo = (key, serviceOrderId, budgetId) =>
  get(['serviceorders', serviceOrderId, 'budgets', budgetId, 'edit-execution-screen-info'], {
    key,
  })

export const verifyPaidInstallment = (key, serviceOrderId, budgetId) =>
  get(['serviceorders', serviceOrderId, 'budgets', budgetId, 'verify-paid-installment'], { key })

export const fetchPaidInstallments = (key, budgetId) =>
  get(['budgets', budgetId, 'paid-installments'], { key })

export const fetchBudgetServices = (key, params) =>
  get(['budget-services'], { key, params: humps.decamelizeKeys(params), transformPayload: true })

export const postBudgetService = (key, payload) =>
  post(['budget-services'], { key, transformPayload: true }, payload)

export const patchBudgetService = (key, serviceId, payload) =>
  patch(['budget-services', serviceId], { key, transformPayload: true }, payload)

export const deleteBudgetService = (key, serviceId) => del(['budget-services', serviceId], { key })

export const fetchBudgetImportSuggestions = (key, budgetId, payload) =>
  post(['budgets', budgetId, 'import-suggestions'], { key }, payload)

export const fetchBudgetUndoImportSuggestions = (key, budgetId, payload) =>
  post(['budgets', budgetId, 'undo-import-suggestions'], { key }, payload)

export const fetchBudgetAdjustServices = (key, budgetId, payload) =>
  post(['budgets', budgetId, 'budget-adjust-services'], { key }, payload)

export const getReasonsToRefusal = key => get(['budget-refusal-reasons'], { key })

export const createBudgetTemplate = key => (budgetId, payload) =>
  post(
    ['budgets', budgetId, 'create-budget-template'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const postBudgetSaveTemplates = (key, budgetId, payload) =>
  post(['budgets', budgetId, 'budget-save-templates'], { key }, payload)

export const patchBudgetSaveTemplates = (key, budgetId, payload, params) =>
  patch(
    ['budgets', budgetId, 'edit-budget-save-templates'],
    { key, params: humps.decamelizeKeys(params), transformPayload: true },
    payload
  )

export const deleteBudgetSaveTemplates = (key, budgetId, params) =>
  del(['budgets', budgetId, 'delete-budget-save-templates'], {
    key,
    params: humps.decamelizeKeys(params),
    transformPayload: true,
  })

export const getBudgetSaveTemplates = (key, budgetId) =>
  get(['budgets', budgetId, 'budget-save-templates'], { key })

export const getBudgetLoadTemplates = key => get(['budget-template-service'], { key })

export const postBudgetLoadTemplates = (key, budgetId, payload) =>
  post(['budgets', budgetId, 'budget-load-templates'], { key }, payload)

export const getVerifyBudgetFinancialValues = (key, budgetId) =>
  get(['budgets', budgetId, 'verify-budget-financial-values'], { key })

export const getBudgetStatus = (key, budgetId) =>
  get(['budgets', budgetId, 'budget-status'], { key })

export const createCoverageRule = (key, payload) => post(['coverage-rules'], { key }, payload)

export const getCoverageRuleVersion = (key, params = {}) =>
  get(['coverage-rules', 'get-version'], { key, params })

export const updateBudgetCoverage = (key, budgetId, payload) =>
  patch(['coverage-rules', budgetId, 'update-budget-coverage'], { key }, payload)
